const API_HOST = process.env.REACT_APP_API_HOST || 'https://app.agent700.ai/api';

const routes = {
  signup: `${API_HOST}/auth/signup`,
  login: `${API_HOST}/auth/login`,
  logout: `${API_HOST}/auth/logout`,
  requestPasswordReset: `${API_HOST}/auth/request-password-reset`,
  resetPassword: `${API_HOST}/auth/reset-password`,
  heartbeat: `${API_HOST}/auth/heartbeat`,
  chat: `${API_HOST}/chat`,
  streamChat: `${API_HOST}/stream-chat`,
  agents: `${API_HOST}/agents`,
  workflows: `${API_HOST}/workflows`,
  organizations: `${API_HOST}/organizations`,
  ratings: `${API_HOST}/ratings`,
  agentRatings: `${API_HOST}/ratings/agent/:agentId`,
  agentRevisionRatings: `${API_HOST}/ratings/agent/:agentId/agentRevision/:agentRevisionId`,
  qaSheetsForAgent: (agentId) => `${API_HOST}/agents/${agentId}/qa-sheets`,
  qaSheetRevisions: (agentId, qaSheetId) => `${API_HOST}/agents/${agentId}/qa-sheets/${qaSheetId}`,
  ratingsExport: `${API_HOST}/ratings-export`,
  billingUser: `${API_HOST}/billing/user`,
  billingOrganization: `${API_HOST}/billing/organization`,
  alignmentData: `${API_HOST}/alignment-data`,
  helpersParseDocument: `${API_HOST}/helpers/parse-document`,
};

export default routes;
