import { SnackbarProvider } from "notistack";

// -------------------------------------------------------------------------

export default function NotistackProvider({ children }) {
  return(
    <SnackbarProvider
      maxSnack={1}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
