// NavigationSideDrawer.js
import React from 'react';
import {
  Drawer,
  Tabs,
  Tab,
  Box,
  IconButton,
  useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigationContext } from "src/contexts/NavigationContext";
import AgentList from './AgentList';
import AgentDetails from './AgentDetails';
import WorkflowList from './WorkflowList';
import WorkflowDetails from './WorkflowDetails';
import AlignmentData from './AlignmentData';
import { useAgentContext } from 'src/contexts/AgentContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      style={{ overflowY: 'auto' }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const isSharedAgent = window.location.pathname.startsWith('/share/agent/')

export default function NavigationSideDrawer() {
  const { setIsSideDrawerVisible, navigationPane, setNavigationPane, selectedTabIndex, setSelectedTabIndex } = useNavigationContext();
  const { selectedAgent, selectedAgentRevision } = useAgentContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isSharedAgent) {
    return null;
  }

  const handleTabChange = (event, newValue) => {
    // Based on the tab index, set the navigation pane accordingly
    let pane;
    switch (newValue) {
      case 0:
        if (selectedAgent && selectedAgentRevision) {
          pane = 'agentDetails';
        } else {
          pane = 'agentList';
        }
        break;
      case 1:
        pane = 'workflowList';
        break;
      case 2:
        pane = 'alignmentData';
        break;
      case 3:
        pane = 'qaList';
        break;
      default:
        pane = 'agentList';
    }

    setNavigationPane(pane);
    setSelectedTabIndex(newValue);
  };

  // Define styles that were previously in useStyles
  const drawerPaperStyles = {
    p: 0,
    maxWidth: '100%',
    marginTop: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,

    [theme.breakpoints.down('sm')]: {
      width: '85vw', // 85% of the viewport width on small screens
    },
  };

  const closeButtonStyles = {
    position: 'absolute',
    right: theme.spacing(1)
  };

  return (
    <Drawer
      open
      anchor="left"
      onClose={() => setIsSideDrawerVisible(false)}
      variant={isMobile ? "temporary" : "persistent"}
      PaperProps={{
        sx: drawerPaperStyles,
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: isMobile ? 'sticky' : 'relative',
            top: 0,
            backgroundColor: 'background.paper',
            zIndex: 10,
            boxShadow: isMobile ? theme.shadows[3] : 'none',
          }}
        >
          <Tabs
            value={selectedTabIndex}
            onChange={handleTabChange}
            aria-label="Navigation Tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{ flexGrow: 1 }}
          >
            <Tab label="Agents" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
            <Tab label="Collaborations" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
            <Tab label="Data Vault" id="simple-tab-2" aria-controls="simple-tabpanel-2" />
            {/* <Tab label="Q&A" id="simple-tab-3" aria-controls="simple-tabpanel-3" /> */}
          </Tabs>
          <IconButton onClick={() => setIsSideDrawerVisible(false)} sx={closeButtonStyles}>
            <CloseIcon />
          </IconButton>
        </Box>

        <TabPanel value={selectedTabIndex} index={0}>
          {navigationPane === 'agentList' && <AgentList />}
          {navigationPane === 'agentDetails' && <AgentDetails />}
        </TabPanel>

        <TabPanel value={selectedTabIndex} index={1}>
          {navigationPane === 'workflowList' && <WorkflowList />}
          {navigationPane === 'workflowDetails' && <WorkflowDetails />}
        </TabPanel>

        <TabPanel value={selectedTabIndex} index={2}>
          <AlignmentData />
        </TabPanel>

        {/* <TabPanel value={selectedTabIndex} index={3}>
          Q&A Tab Content
          Implement your Q&A content here
        </TabPanel> */}
      </Box>
    </Drawer>
  );
}