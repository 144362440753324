// NavigationContext.js
import React, { createContext, useState, useContext } from 'react';
const NavigationContext = createContext();

const isSharedAgent = window.location.pathname.startsWith('/share/agent/')

function NavigationProvider({ children }) {
  const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(false);
  const [navigationPane, setNavigationPane] = useState('agentList');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const openNavigationPane = (pane) => {
    if (!!pane) {
      setNavigationPane(pane);

      // Map pane to tab index
      let index;
      switch (pane) {
        case 'agentList':
        case 'agentDetails':
          index = 0;
          break;
        case 'workflowList':
        case 'workflowDetails':
          index = 1;
          break;
        case 'alignmentData':
          index = 2;
          break;
        case 'qaList':
          index = 3;
          break;
        default:
          index = 0;
      }
      setSelectedTabIndex(index);
    }
    if (!isSharedAgent) {
      setIsSideDrawerVisible(true);
    }
  }

  return (
    <NavigationContext.Provider value={{
      isSideDrawerVisible,
      setIsSideDrawerVisible,
      navigationPane,
      setNavigationPane,
      openNavigationPane,
      selectedTabIndex,
      setSelectedTabIndex
    }}>
      {children}
    </NavigationContext.Provider>
  );
}

function useNavigationContext() {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("useNavigationContext must be used within an NavigationProvider");
  }
  return context;
}

export { NavigationProvider, useNavigationContext };