import React from 'react';
import './App.css';
import ThemeConfig from "./theme";
import ThemePrimaryColor from './components/ThemePrimaryColor';
import Navbar from "src/components/Navbar";
import NavigationSideDrawer from 'src/components/NavigationSideDrawer';
import { useNavigationContext } from 'src/contexts/NavigationContext';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Router from './routes';

function App() {
  const { isSideDrawerVisible } = useNavigationContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        {isSideDrawerVisible && <NavigationSideDrawer />}
        <Box
          sx={{
            marginLeft: (isSideDrawerVisible && !isMobile) ? 75 : 0,
            overflowX: isMobile ? 'scroll' : 'auto',
            transition: 'margin-left 1s ease-in-out'
          }}
        >
          <Navbar />
          <Router />
        </Box>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

export default App;
