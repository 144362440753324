import React, { createContext, useState, useCallback } from 'react';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: '',
    variant: 'info' // default to info
  });

  const openSnackbar = useCallback((message, variant = 'info') => {
    setSnackbarData({ open: true, message, variant });
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarData({ open: false, message: '' });
  }, []);

  return (
    <SnackbarContext.Provider value={{ snackbarData, openSnackbar, closeSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
