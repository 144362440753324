// AlignmentDataAutocompleteEditor.js
import React, { useState, useEffect, useRef } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import AutocompletePlugin from './AutocompletePlugin';
import { MentionNode } from './MentionNode';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import _ from 'lodash';
import { styled, useTheme } from '@mui/material/styles';
import { v4 as uuid } from 'uuid';

const CustomLexicalField = styled(ContentEditable)(({ theme }) => ({
  // Mimic TextField styles
  padding: '0.25rem 0.5rem',
  lineHeight: '1.4375rem',
  width: '100%',
  textIndent: 3,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column', // Ensure flex items stack vertically
  boxSizing: 'border-box',
  border: `1px solid rgba(0, 0, 0, 0.23)`,
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
  },
  '&:hover': {
    outline: 'none',
    borderColor: theme.palette.common.black,
  },
  '&:focus:hover': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
  },
  '> p': {
    marginBottom: '0.25rem',
    marginTop: 0,
    minHeight: '1.4375rem', // Set a minimum height equal to the line height
  },
  // Ensure empty paragraphs take up space
  '> p:empty:before': {
    content: '"\\00a0"', // Add a non-breaking space to empty paragraphs
  },
  maxHeight: '80vh',
  overflow: 'auto'
}));

const ManualMuiTextFieldStyles = styled('div')(({ theme }) => ({
  // Mimic TextField styles
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: '0',
  padding: '0',
  margin: '0',
  border: '0',
  verticalAlign: 'top',
  width: '100%',
  background: '#fff',
  borderRadius: '4px',
  marginTop: '8px',
  marginBottom: '8px',
}));


const AlignmentDataAutocompleteEditor = ({ customComponentId, onKeyDown, onChange, inputText, placeholder, label='', minLines = 3, inputAdornment, editable=true }) => {
  const [isFocused, setIsFocused] = useState(false);

  const [composerKey, setComposerKey] = useState(1);
  const resetComposerKey = () => {
    // Set a new LexicalComposer key if the inputText prop changes from outside the text editor.
    // This will reload the composer to show the new text.
    setComposerKey(uuid());
  }

  const isInternalChangeRef = useRef(false); // Ref to track if the change is internal

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const theme = useTheme();

  function initializeText() {
    if (!_.trim(inputText)) {
      return null;
    }
    const root = $getRoot();
    if (root.getFirstChild() === null) {
      const paragraph = $createParagraphNode();
      paragraph.append(
        $createTextNode(inputText),
      );
      root.append(paragraph);
    }
  }

  useEffect(() => {
    if (inputText !== undefined && !isInternalChangeRef.current) {
      resetComposerKey();
    }
    // Reset the flag after checking
    isInternalChangeRef.current = false;
  }, [inputText]);

  const editorConfig = {
    namespace: 'Lexical Editor',
    onError,
    nodes: [MentionNode],
    editorState: initializeText,
    editable: editable
  };

  function onError(error) {
    console.error(error);
  }

  const handleChange = (editorState, editor) => {
    const stringifiedEditorState = JSON.stringify(
      editor.getEditorState().toJSON(),
    );
    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);

    const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent())
    if (editorStateTextString !== inputText) {
      onChange(editorStateTextString);
      isInternalChangeRef.current = true;
    }
  };

  let adjustedMinLines = minLines < 1 ? 1 : minLines;
  if (label) {
    adjustedMinLines += 1;
  }

  return (<ManualMuiTextFieldStyles>
    <div style={{
      transform: isFocused || inputText ? 'matrix(1, 0, 0, 1, 8, 10)' : 'matrix(1, 0, 0, 1, 8, 39)',
      pointerEvents: 'none',
      transitionProperty: 'transform, max-width',
      color: isFocused ? 'rgb(84, 214, 44)' : 'rgb(99, 115, 129)',
      paddingLeft: 0,
      paddingRight: 0,
      width: isFocused || inputText ? 200 : '70%',
      zIndex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      transition: 'all 0.2s ease',
    }}>
      <label style={{
        fontSize: isFocused || inputText ? '80%' : '100%',
        transition: 'all 0.2s ease',
        pointerEvents: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
      >
        {label}
      </label>
    </div>
    <LexicalComposer initialConfig={editorConfig} key={`LexicalComposer-${composerKey}`}>
        <PlainTextPlugin
          contentEditable={
            <CustomLexicalField
              id={`${customComponentId}`}
              style={{
                minHeight: `${_.max([adjustedMinLines*1.4375, 2.5786])}rem`,
                paddingRight: inputAdornment ? '2.5rem' : null,
                paddingTop: '0.5rem',
                borderColor: !editable ? theme.palette.action.disabled : null,
                color: !editable ? theme.palette.action.disabled : null,
               }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={onKeyDown}
              />
          }
          placeholder={
            <div style={{
              pointerEvents: 'none',
              position: 'absolute', top: label ? '2rem' : '0.5rem', left: '.75rem',
              display: 'inline-block',
              color: 'rgb(99, 115, 129)',
            }}
            >
              {isFocused || !label ? placeholder : ''}
            </div>
          }
        >
        </PlainTextPlugin>
        <HistoryPlugin />
        <OnChangePlugin onChange={handleChange} />
        <AutocompletePlugin />
        {inputAdornment}
    </LexicalComposer>
  </ManualMuiTextFieldStyles>
  );
};

export default AlignmentDataAutocompleteEditor;