import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import routes from 'src/config/routes';

const OrganizationContext = createContext();

export const useOrganizationContext = () => {
  return useContext(OrganizationContext);
};

export const OrganizationProvider = ({ children }) => {
  const [members, setMembers] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState({});

  const fetchOrganizationMembers = async () => {
    try {
      const response = await axios.get(`${routes.organizations}/members`);
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching organization members:', error);
    }
  };
  
  const organizationValue = {
    fetchOrganizationMembers,
    members,
    selectedOrganization, 
    setSelectedOrganization,
  };

  return (
    <OrganizationContext.Provider value={organizationValue}>
      {children}
    </OrganizationContext.Provider>
  );
};
