import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from '@mui/styled-engine';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAgentContext } from '../contexts/AgentContext';
import { useNavigationContext } from '../contexts/NavigationContext';
import { useAuth } from '../contexts/AuthContext';
import routes from 'src/config/routes'
import axios from 'axios';
import { useWorkflowContext } from 'src/contexts/WorkflowContext';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import { isString } from 'lodash';

// -------------------------------------------------------------------------

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  background: theme.palette.grey[700],
  minHeight: 40,
}));

const useStyles = makeStyles(theme => ({
  drawerIconButton: {
    color: theme.palette.common.white,
  },
  rightAlignedContainer: {
    marginLeft: 'auto', // Push the container to the right
    display: 'flex',
    alignItems: 'center'
  },
  logoutButton: {
    marginRight: theme.spacing(3)
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerIconButton: {
    color: theme.palette.common.white,
  },
  rightAlignedContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}));

// -------------------------------------------------------------------------

function Navbar() {
  const classes = useStyles();
  const location = useLocation();
  const { selectedAgent, setAgents, setPersonalAgents, setOrganizationAgents, selectedAgentRevision, refreshAgentsFromServer } = useAgentContext();
  const { selectedWorkflow, selectedWorkflowRevision } = useWorkflowContext();
  const { setIsSideDrawerVisible, isSideDrawerVisible, openNavigationPane } = useNavigationContext();
  const { selectedOrganization, setSelectedOrganization } = useOrganizationContext();
  const navigate = useNavigate();
  const { logout, user, isLoggedIn } = useAuth();
  const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(null);

  useEffect(() => {
    if (user && user.organizationsRoles && user.organizationsRoles.length > 0) {
      setSelectedOrganization(user.defaultOrganization.id); // Set the default organization as selected
    }
  }, [user]);

  useEffect(() => {
    if (selectedOrganization && isString(selectedOrganization) && isLoggedIn) {
      refreshAgentsFromServer();
    }
  }, [selectedOrganization, isLoggedIn]);

  const handleProjectDrawerToggle = () => {
    setIsSideDrawerVisible(!isSideDrawerVisible);
  };

  // When the organization is changed
  const handleOrganizationChange = async (event) => {
    setSelectedOrganization(event.target.value);
  };

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchorElement(null);
  };

  const handleLogout = () => {
    logout();
    handleCloseUserMenu(); // Close the menu
  };

  const handleClickBilling = () => {
    navigate('/billing'); // Navigate to the billing page
    handleCloseUserMenu(); // Close the menu
  };

  //if (location.pathname.includes('/share')) {
  //  return null; // Don't render the navbar for /share links
  //}

  return (
    <div className="navbar">
      <AppBarStyled position="fixed" style={{ paddingLeft: isSideDrawerVisible ? '650px' : null, backgroundColor: '#2e2c2f' }}>
        <Toolbar style={{ display: 'flex' }}>
          {isLoggedIn && <IconButton
            edge="start"
            className={classes.drawerIconButton}
            aria-label="menu"
            onClick={handleProjectDrawerToggle}
          >
            <MenuIcon />
          </IconButton>}

          {(!selectedAgentRevision && !selectedWorkflowRevision) &&
            <Typography variant="h6" className="navbar-title" style={{
              fontFamily: 'monospace',
              backgroundColor: 'yellow',
              color: 'rgb(69, 79, 91)',
              outline: '5px solid yellow',
              outlineTop: '10px solid yellow',
              marginLeft: 45
            }}>
              {`No Agent Selected`}
            </Typography>
          }

          {(selectedAgentRevision || selectedWorkflowRevision) &&
            <Typography variant="h6" className="navbar-title" style={{
              fontFamily: 'monospace',
              backgroundColor: 'yellow',
              color: 'rgb(69, 79, 91)',
              outline: '5px solid yellow',
              outlineTop: '10px solid yellow',
              marginLeft: 45
            }}>
              {selectedWorkflow && `${selectedWorkflowRevision?.name || 'New Workflow'}`} {selectedWorkflow && selectedAgent && ` - `} {`${selectedAgentRevision?.name || 'New Agent'}`}
            </Typography>
          }

          {isLoggedIn && (
            <div className={classes.rightAlignedContainer}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleUserMenuClick}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={userMenuAnchorElement}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(userMenuAnchorElement)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleClickBilling}>{user.email}</MenuItem>
                <MenuItem>
                  <Select
                    value={selectedOrganization}
                    onChange={handleOrganizationChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {user && user.organizationsRoles && user.organizationsRoles.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </MenuItem>
                <MenuItem onClick={handleClickBilling}>Usage</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBarStyled>
    </div>
  );
}

export default Navbar;
