import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, List, ListItem, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAgentContext } from "src/contexts/AgentContext";
import { useWorkflowContext } from "src/contexts/WorkflowContext";
import { useNavigationContext } from "src/contexts/NavigationContext";
import ClearIcon from '@mui/icons-material/Clear';
import { v4 as uuid } from 'uuid';
import { useStyles } from './WorkflowListStyles';

export default function WorkflowList() {
  const classes = useStyles();
  const {
    agents,
    setSelectedAgent,
    setSelectedAgentRevision
  } = useAgentContext();
  const {
    workflows, addWorkflow, deleteWorkflow, defaultWorkflow, setSelectedWorkflow, setSelectedWorkflowRevision, setSelectedWorkflowRevisionIndex
  } = useWorkflowContext();
  const { openNavigationPane } = useNavigationContext();

  // States for the confirmation dialog
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  // Open the confirmation dialog and store the workflow ID
  const handleOpenDialog = (workflowId) => {
    setWorkflowToDelete(workflowId);
    setDialogOpen(true);
  };

  // Close the confirmation dialog and reset the workflow ID
  const handleCloseDialog = () => {
    setWorkflowToDelete(null);
    setDialogOpen(false);
  };

  // Delete the workflow and close the dialog
  const handleConfirmDelete = () => {
    if (workflowToDelete) {
      deleteWorkflow(workflowToDelete);
    }
    handleCloseDialog();
  };

  const handleAddNewWorkflow = () => {
    const newWorkflow = { ...defaultWorkflow, id: uuid() }
    addWorkflow(newWorkflow);
    handleSelectWorkflow(newWorkflow);
  };

  const handleSelectWorkflow = (workflow) => {
    // Find agent and agent revision based on the workflow's first agent ID
    const latestWorkflowRevision = workflow.revisions[workflow.revisions.length - 1];
    const firstAgentId = latestWorkflowRevision.agentIds.length > 0 ? latestWorkflowRevision.agentIds[0] : null;
    const firstAgent = firstAgentId ? agents.find(agent => agent.id === firstAgentId) : null;
    const latestAgentRevision = firstAgent ? firstAgent.revisions[firstAgent.revisions.length - 1] : null;
    setSelectedAgent(firstAgent);
    setSelectedAgentRevision(latestAgentRevision);
    setSelectedWorkflow(workflow)
    setSelectedWorkflowRevision(latestWorkflowRevision)
    setSelectedWorkflowRevisionIndex(workflow.revisions.length - 1)
    openNavigationPane('workflowDetails')
  };

  //console.log('Workflows: ', workflows);

  if (!workflows.length) {
    return (
      <div>
        <Typography variant="body1" style={{ padding: '1rem' }}>
          No workflows found. Click the <AddIcon /> button below to add a new workflow.
        </Typography>
        <IconButton color="default" onClick={handleAddNewWorkflow} className={classes.addWorkflowButton}>
          <AddIcon />
        </IconButton>
      </div>
    );
  }
  return (
    <div style={{ width: '535px', maxWidth: '100%' }}>
      <IconButton color="default" onClick={handleAddNewWorkflow} className={classes.addWorkflowButton}>
        <AddIcon />
      </IconButton>
      <List>
        {workflows.map((workflow) => (
          <ListItem button key={workflow.id} onClick={() => { handleSelectWorkflow(workflow) }}>
            <ListItemText
              primary={workflow.revisions[workflow.revisions.length - 1].name}
            />
            <ListItemIcon>
              <IconButton edge="end" onClick={(e) => {
                e.stopPropagation();
                handleOpenDialog(workflow.id);
              }}>
                <ClearIcon />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Workflow"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this workflow?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
