// AlignmentData.js
import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';

import {
  Button,
  Typography,
  TextField,
  Alert,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Divider,
  useMediaQuery,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { AlignmentDataContext } from 'src/contexts/AlignmentDataContext';
import routes from 'src/config/routes';
import SnackbarContext from 'src/contexts/SnackbarContext';

// -------------------------------------------------------------------------

const VariableTextArea = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    overflow: 'auto !important',
    maxHeight: '65vh',
  },
}));

// -------------------------------------------------------------------------

function AlignmentData() {
  const [error, setError] = useState('');
  const [useOCR, setUseOCR] = useState(false);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [editingVariable, setEditingVariable] = useState(false);
  const [variableKey, setVariableKey] = useState('');
  const [variableValue, setVariableValue] = useState('');
  const [originalKey, setOriginalKey] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [renameConfirmOpen, setRenameConfirmOpen] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState('');
  const variableKeyRef = useRef(null);
  const fileInputRef = useRef(null);
  const {
    alignmentData,
    addOrUpdateAlignmentData,
    deleteAlignmentDataItem,
    alignmentDataKeyNameAntiPattern,
  } = useContext(AlignmentDataContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddVariableClick = () => {
    setVariableKey('');
    setVariableValue('');
    setOriginalKey(null);
    setEditingVariable(true);
    setError('');
  };

  const handleEditVariableClick = (key) => {
    setVariableKey(key);
    setVariableValue(alignmentData[key]);
    setOriginalKey(key);
    setEditingVariable(true);
    setError('');
  };

  const handleCopyVariableClick = (key) => {
    const valueToCopy = `{{${key}}}`;

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(valueToCopy)
        .then(() => {
          openSnackbar('Variable value copied to clipboard.', 'success');
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
          openSnackbar('Failed to copy variable value.', 'error');
        });
    } else {
      // Fallback for browsers that don't support the Clipboard API
      try {
        const textArea = document.createElement('textarea');
        textArea.value = valueToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        openSnackbar('Variable value copied to clipboard.', 'success');
      } catch (err) {
        console.error('Failed to copy: ', err);
        openSnackbar('Failed to copy variable value.', 'error');
      }
    }
  };

  useEffect(() => {
    if (editingVariable && variableKeyRef.current) {
      variableKeyRef.current.scrollIntoView({ behavior: 'smooth' });
      variableKeyRef.current.focus();
    }
  }, [variableKey, editingVariable]);

  const handleDeleteVariable = (key) => {
    setKeyToDelete(key);
    setDeleteConfirmOpen(true);
  };

  const confirmDeleteVariable = async () => {
    const result = await deleteAlignmentDataItem(keyToDelete);
    if (result) {
      // If the variable being edited is deleted, close and clear the edit form
      if (variableKey === keyToDelete || originalKey === keyToDelete) {
        setEditingVariable(false);
        setVariableKey('');
        setVariableValue('');
        setOriginalKey(null);
        setError('');
      }
    }
    setDeleteConfirmOpen(false);
    setKeyToDelete('');
  };

  const handleCancelEdit = () => {
    setEditingVariable(false);
    setError('');
  };

  const handleSaveVariable = async () => {
    const keyToSave = variableKey.trim();
    if (!keyToSave) {
      setError('Variable name cannot be empty.');
      return;
    }
    if (alignmentDataKeyNameAntiPattern.test(keyToSave)) {
      setError(
        'Variable name contains invalid characters. Only letters, numbers, periods, dashes, and underscores are allowed.'
      );
      return;
    }

    if (originalKey && originalKey !== keyToSave) {
      // Show rename confirmation dialog
      setRenameConfirmOpen(true);
    } else {
      // proceed with saving
      await saveVariable(keyToSave);
    }
  };

  const saveVariable = async (keyToSave) => {
    const result = await addOrUpdateAlignmentData(keyToSave, variableValue, originalKey);
    if (result === true) {
      setError('');
      // Optionally, you can show a success message here
      openSnackbar('Variable saved successfully.', 'success');
    } else {
      // Display the error message returned from addOrUpdateAlignmentData
      setError(result);
      openSnackbar(result, 'error');
    }
  };

  const handleFileUploadInForm = async () => {
    setIsProcessingFile(true);
    const files = Array.from(fileInputRef.current.files);

    if (!files.length) {
      setIsProcessingFile(false);
      openSnackbar('Please select a file to upload.', 'error');
      return;
    }

    for (const file of files) {
      const formData = new FormData();
      formData.append('document', file);

      try {
        const response = await axios.post(`${routes.helpersParseDocument}?use_ocr=${useOCR}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data.text) {
          const newValue = response.data.text.trim();

          setVariableValue((prevValue) => `${prevValue}\n${newValue}`.trim());

          if (!variableKey.trim()) {
            const fileName = file.name;
            const isCollection = files.length > 1;
            const suggestedVariableKey = isCollection
              ? 'collection.'
              : fileName.replace(alignmentDataKeyNameAntiPattern, '');

            setVariableKey(suggestedVariableKey);
          }

          openSnackbar('File processed and content appended successfully.', 'success');
        } else {
          openSnackbar('Failed to process file.', 'error');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        openSnackbar('Failed to upload file. Please try again.', 'error');
      }
    }

    fileInputRef.current.value = null;
    setIsProcessingFile(false);
  };

  // For search/filter
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAlignmentDataKeys = Object.keys(alignmentData).filter((key) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      sx={{
        display: isMobile ? 'block' : 'flex',
        height: '100vh',
        minHeight: 0,
      }}
    >
      {/* Left Sidebar with Alignment Data List */}
      <Box
        sx={{
          width: isMobile ? '100%' : '30vw',
          flexShrink: 0,
          marginRight: isMobile ? '0' : editingVariable ? '1rem' : '0',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: 0,
        }}
      >
        {error && (
          <Alert severity="error" sx={{ mb: '1rem' }}>
            {error}
          </Alert>
        )}

        <Typography variant="body2" sx={{ mb: '1rem' }}>
          This is a place where you can set shared variables that can be used across different agents or
          workflows' system instructions. Example variable names: my_variable, global.name, product.info.description.
        </Typography>
        <Typography variant="body2" sx={{ mb: '1rem' }}>
          You can reference your variables in your system instructions like: <code>{'{{my_variable}}'}</code>,{' '}
          <code>{'{{product.info.description}}'}</code>, and it will be replaced with the value you set here.
        </Typography>

        {/* Add Variable Button */}
        <Box sx={{ mb: '1rem', display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddVariableClick}
            sx={{ mr: '1rem' }}
          >
            Add Variable
          </Button>
        </Box>

        {/* Search input box */}
        <TextField
          label="Search Variables"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: '1rem' }}
        />

        <Divider />

        {/* List of alignment data items */}
        <List sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
          {filteredAlignmentDataKeys.map((key) => (
            <ListItem key={key} button onClick={() => handleEditVariableClick(key)}>
              <ListItemText
                primary={key.length > 50 ? `${key.substring(0, 50)}...` : key}
                secondary={
                  alignmentData[key]?.length > 50
                    ? `${alignmentData[key].substring(0, 50)}...`
                    : alignmentData[key]
                }
              />
              <Tooltip title="Copy Variable">
                <IconButton edge="end" onClick={(event) => {
                  event.stopPropagation(); // Don't call handleEditVariableClick on the ListItem
                  handleCopyVariableClick(key);
                }}>
                  <CopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton edge="end" onClick={() => handleEditVariableClick(key)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton edge="end" onClick={() => handleDeleteVariable(key)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Right Side: Edit Form */}
      {editingVariable && (
        <>
          {/* Divider */}
          {!isMobile && <Divider orientation="vertical" flexItem />}

          <Box
            sx={{
              width: isMobile ? '100%' : '100vw',
              mt: isMobile ? '1rem' : '0',
              ml: isMobile ? '0' : '1rem',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              height: '100%',
              minHeight: 0, // Important for child flex items
            }}
          >
            {/* Heading and Action Buttons */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '1rem',
              }}
            >
              <Typography variant="h6">
                {originalKey ? 'Edit Variable' : 'Add Variable'}
              </Typography>
              <Box>
                <Button onClick={handleCancelEdit} color="secondary" sx={{ mr: '1rem' }}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveVariable}
                  color="primary"
                  variant="contained"
                  disabled={!variableKey.trim()}
                >
                  Save
                </Button>
              </Box>
            </Box>
            <TextField
              autoFocus
              fullWidth
              label="Name"
              type="text"
              margin="dense"
              inputRef={variableKeyRef}
              value={variableKey}
              sx={{ mb: '1rem' }}
              onChange={(e) => {
                const filteredValue = e.target.value.replace(alignmentDataKeyNameAntiPattern, '');
                setVariableKey(filteredValue);
              }}
            />
            <Box
              sx={{
                minHeight: 0, // Allows child to shrink
                maxHeight: '80vh', // Optional: limit the max height
                overflow: 'hidden', // Prevent parent from scrolling
              }}
            >
              <VariableTextArea
                multiline
                fullWidth
                label="Text"
                type="text"
                margin="dense"
                minRows={10}
                value={variableValue}
                onChange={(e) => setVariableValue(e.target.value)}
              />
            </Box>
            {/* Footer and File Upload Section */}
            <Box sx={{ position: 'relative', mt: '1rem', mb: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <input
                  multiple
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileUploadInForm}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isProcessingFile}
                  onClick={() => fileInputRef.current.click()}
                >
                  {isProcessingFile ? <CircularProgress size={24} /> : 'Upload Files'}
                </Button>
                <FormControlLabel
                  label="Scan Images for Text"
                  sx={{ ml: '1rem' }}
                  control={
                    <Checkbox
                      name="useOCR"
                      color="primary"
                      checked={useOCR}
                      onChange={() => setUseOCR(!useOCR)}
                    />
                  }
                />
              </Box>
              <Box sx={{ mb: '1rem' }}>
                <Button onClick={handleCancelEdit} color="secondary" sx={{ mr: '1rem' }}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveVariable}
                  color="primary"
                  variant="contained"
                  disabled={!variableKey.trim()}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting Data Vault keys may disrupt existing Agents that use the current key name. Are you sure you want to
            delete the key "{keyToDelete}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteVariable} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Rename Confirmation Dialog */}
      <Dialog open={renameConfirmOpen} onClose={() => setRenameConfirmOpen(false)}>
        <DialogTitle>Confirm Rename</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Renaming Data Vault keys may disrupt existing Agents that use the current key name. Are you sure you want
            to rename the key from "{originalKey}" to "{variableKey}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setRenameConfirmOpen(false);
              saveVariable(variableKey.trim());
            }}
            color="secondary"
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AlignmentData;