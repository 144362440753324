import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import SnackbarContext from 'src/contexts/SnackbarContext';

function SnackbarComponent() {
  const { snackbarData, closeSnackbar } = useContext(SnackbarContext);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={snackbarData.open}
      autoHideDuration={3000}
      onClose={closeSnackbar}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Alert onClose={closeSnackbar} severity={snackbarData.variant} variant="filled">
        {snackbarData.message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarComponent;
