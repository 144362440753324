import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    console.log({key, storedValue})
    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(JSON.parse(e.newValue));
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (newValue) => {
    setValue((currentValue) => {
      let processedValue;
      if (typeof newValue === 'function') {
        processedValue = newValue(currentValue);  // Resolve the function to get the new value
      } else {
        processedValue = newValue;
      }
      console.log({saveNewKey: key, saveNewValue: processedValue})
      localStorage.setItem(key, JSON.stringify(processedValue));  // Save the resolved value, not the function
      return processedValue;
    });
  };

  return [value, setValueInLocalStorage];
};
