import React, { createContext, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import routes from 'src/config/routes'; // Assuming you have defined routes for API endpoints
import SnackbarContext from 'src/contexts/SnackbarContext';
import { v4 as uuid } from 'uuid';

const QASheetContext = createContext();

const defautQASheet = {
  id: null,
  agentId: null,
  name: '',
  rows: [],
}

const defaultQASheetRow = {
  question: '',
  answer: '',
  notes: '',
  tags: [],
  approved: false,
};

function QASheetProvider({ children }) {
  const [qaSheets, setQASheets] = useState([]);
  const [selectedQASheet, setSelectedQASheet] = useState(null);
  const { openSnackbar } = useContext(SnackbarContext);

  const fetchQASheets = useCallback(async () => {
    try {
      const response = await axios.get(routes.qaSheets);
      if (response.status === 200 || response.status === 201) {
        setQASheets(response.data.qaSheets);
      }
    } catch (error) {
      openSnackbar('Error fetching QA Sheets.', 'error');
    }
  }, [openSnackbar]);

  const fetchQASheetForAgent = useCallback(async (agentId) => {
    try {
      const response = await axios.get(routes.qaSheetsForAgent(agentId));
      if ((response.status === 200 || response.status === 201) && response.data && response.data.length > 0) {
        setSelectedQASheet(response.data[0]);
      } else {
        setSelectedQASheet({
          ...defautQASheet,
          agentId,
          id: uuid(),
          rows: [{ ...defaultQASheetRow, id: uuid() }]
        });
      }
    } catch (error) {
      openSnackbar('Error fetching QA Sheet for agent.', 'error');
    }
  }, [openSnackbar]);

  const addQASheetRow = () => {
    if (selectedQASheet) {
      setSelectedQASheet(prevQASheet => {
        // Clone the rows array from the latest revision and add the new row
        const newRows = [
          { ...defaultQASheetRow, id: uuid() },
          ...prevQASheet?.latestRevision?.rows || [],
        ];
        // Clone the latestRevision object and update its rows
        const updatedLatestRevision = {
          ...prevQASheet.latestRevision,
          rows: newRows
        };
        // Return a new state object with the updated latestRevision
        return {
          ...prevQASheet,
          latestRevision: updatedLatestRevision
        };
      });
    } else {
      openSnackbar('No QA Sheet selected to add a row to.', 'error');
    }
  };

  const updateQASheetRow = (index, column, value) => {
    setSelectedQASheet(prevQASheet => {
      // Clone the rows array from the latest revision
      const updatedRows = [...prevQASheet?.latestRevision?.rows || []];
      // Update the specific row's column with the new value
      updatedRows[index] = { ...updatedRows[index], [column]: value };
      // Clone the latestRevision object and update its rows
      const updatedLatestRevision = { ...prevQASheet.latestRevision, rows: updatedRows };
      // Return a new state object with the updated latestRevision
      return { ...prevQASheet, latestRevision: updatedLatestRevision };
    });
  };

  const saveQASheet = async (agentId, qaSheet) => {
    console.log('<SAVE QA SHEET>');
    try {
      console.log('agentId', agentId);
      console.log('qaSheet', qaSheet);
      const response = await axios.put(routes.qaSheetRevisions(agentId, qaSheet.id), {
        rows: (qaSheet?.latestRevision?.rows || []).map(({ id, question, answer, notes, tags, approved }) => ({
          id, question, answer, notes, tags, approved
        }))
      });
      if (response.status === 200 || response.status === 201) {
        openSnackbar('QA Sheet saved successfully!', 'success');
        setSelectedQASheet(response.data); // Assuming the backend sends back the updated QA sheet
      }
    } catch (error) {
      console.error(error);
      openSnackbar('Failed to save QA Sheet. Please try again.', 'error');
    }
    console.log('</SAVE QA SHEET>')
  };

  return (
    <QASheetContext.Provider value={{
      qaSheets,
      selectedQASheet,
      setSelectedQASheet,
      addQASheetRow,
      updateQASheetRow,
      saveQASheet,
      fetchQASheets,
      fetchQASheetForAgent,
    }}>
      {children}
    </QASheetContext.Provider>
  );
}

function useQASheetContext() {
  const context = useContext(QASheetContext);
  if (!context) {
    throw new Error("useQASheetContext must be used within a QASheetProvider");
  }
  return context;
}

export { QASheetProvider, useQASheetContext };
