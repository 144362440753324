import { styled } from "@mui/material";
import { Outlet } from "react-router-dom";

// -------------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({

}));

// -------------------------------------------------------------------------

export default function ChatLayout() {

  return(
    <RootStyle>
        <Outlet id="chat-outlet" />
    </RootStyle>
  );
}