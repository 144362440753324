import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Paper, TextField, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import routes from 'src/config/routes';
import SnackbarContext from 'src/contexts/SnackbarContext';

// Styles
const useStyles = makeStyles(theme => ({
  formContainer: {
    marginTop: '100px',
    padding: '20px',
  },
}));

// ResetPassword Component
const ResetPassword = () => {
  const { resetCode } = useParams(); // Reading the 'resetCode' parameter from the URL's path
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const classes = useStyles();

  const { openSnackbar } = useContext(SnackbarContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      // Adjust the endpoint as necessary
      const response = await axios.post(routes.resetPassword, {
        newPassword: password,
        resetCode, // Use the resetCode from the URL's path
      });

      console.log('Password reset successful:', response.data);
      openSnackbar('Your password has been reset successfully.', 'success');

      navigate('/login');
    } catch (error) {
      console.error('Password reset failed:', error);
      setErrorMessage(error.response ? error.response.data.error : 'An error occurred. Please try again.');
      openSnackbar('Failed to reset password. Please try again.', 'error');
    }
  };

  return (
    <Container>
      <Paper elevation={3} className={classes.formContainer}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errorMessage && <Typography variant="body2" style={{ color: 'red', marginTop: '20px' }}>{errorMessage}</Typography>}
          <Button variant="contained" color="primary" type="submit">
            Reset Password
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ResetPassword;