import { styled, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: 4
}));

export const useStyles = makeStyles(theme => ({
  drawerPaper: {
    padding: theme.spacing(0),
    width: theme.spacing(60)
  },
  title: {
    fontWeight: 600,
    backgroundColor: 'lightgrey',
    borderBottom: '1px solid black',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  submitButton: {
    marginTop: 'auto',
    background: theme.palette.success.dark,
    '&:hover': {
      background: theme.palette.success.darker
    }
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: 0
  },
  addWorkflowButton: {
    width: '.6in',
    height: '.6in',
    '& svg': {
      fontSize: '0.5in',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  grayBackground: {
    backgroundColor: 'lightgrey'
  },
  saveButtonContainer: {
    marginTop: '2rem',
    textAlign: 'center',
  },
  saveButton: {
    padding: '10px 30px',
  },
  revisionNavigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px' // Gap between items, can adjust based on preference
  }
}));