import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';
import routes from 'src/config/routes';

export const RatingsContext = createContext();

export const RatingsProvider = ({ children }) => {
  const { openSnackbar } = useContext(SnackbarContext);

  const submitRating = async (agentId, agentRevisionId, messages, ratingValue, userNote = '') => {
    try {
      const response = await axios.post(`${routes.ratings}`, {
        agentId,
        agentRevisionId,
        messages,
        rating: ratingValue,
        notes: userNote
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        openSnackbar('Rating submitted successfully!', 'success');
      } else {
        openSnackbar('Failed to submit rating.', 'error');
      }
    } catch (error) {
      openSnackbar('An error occurred while submitting the rating.', 'error');
      console.error('Error submitting rating:', error);
    }
  };

  return (
    <RatingsContext.Provider value={{ submitRating }}>
      {children}
    </RatingsContext.Provider>
  );
};

export const useRatingsContext = () => {
  const context = useContext(RatingsContext);
  if (!context) {
    throw new Error('useRatingsContext must be used within a RatingsProvider');
  }
  return context;
};
