import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import localforage from 'localforage';
import axios from 'axios';
import routes from 'src/config/routes';
import debounce from 'lodash.debounce';
import SnackbarContext from 'src/contexts/SnackbarContext';

const RemoteStorageContext = createContext();

const useStorageForKey = (key, defaultValue, openSnackbar) => {
  const [localDataStore, setLocalDataStore] = useState(null);
  const [syncStatus, setSyncStatus] = useState({
    isSyncing: false,
    lastSynced: null
  });

  useEffect(() => {
    (async () => {
      setSyncStatus(prev => ({ ...prev, isSyncing: true }));

      let data = null;
      try {
        const response = await axios.get(routes[key]);
        data = response.data || defaultValue;
      } catch (error) {
        openSnackbar("Error fetching data");
        data = await localforage.getItem(key) || defaultValue;
      } finally {
        await localforage.setItem(key, data);
        setLocalDataStore(data);
        setSyncStatus({ isSyncing: false, lastSynced: new Date() });
      }
    })();
  }, [key]);

  const debouncedSave = useCallback(
    debounce(async (newData) => {
      try {
        await axios.post(routes[key], newData);
      } catch (error) {
        openSnackbar("Failed to save data to server");
      }
    }, 1000),
    [key]
  );

  const setDataAndSync = (newValue) => {
    setLocalDataStore(prevValue => {
      const updatedValue = typeof newValue === 'function' ? newValue(prevValue) : newValue;
      localforage.setItem(key, updatedValue).then(() => debouncedSave(updatedValue));
      return updatedValue;
    });
  };

  return {
    localDataStore,
    setDataAndSync,
    syncStatus
  };
};

export const RemoteStorageProvider = ({ children }) => {
  const { openSnackbar } = useContext(SnackbarContext);

  return (
    <RemoteStorageContext.Provider value={{ useStorageForKey: (key, defaultValue) => useStorageForKey(key, defaultValue, openSnackbar) }}>
      {children}
    </RemoteStorageContext.Provider>
  );
};

export const useRemoteStorage = (key, defaultValue) => {
  const context = useContext(RemoteStorageContext);
  if (!context) {
    throw new Error("useRemoteStorage must be used within a RemoteStorageProvider");
  }
  return context.useStorageForKey(key, defaultValue);
};

export default RemoteStorageProvider;
