
const breakpoints = {
  values: {
    xs: 300,
    sm: 600,
    md: 960,
    lg: 1280,
    designSize: 1400,
    xl: 1920
  }
};

export default breakpoints;
