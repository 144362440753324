// index.js

import { Suspense, lazy, useEffect } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import ChatLayout from "../layouts/chat";
import { useAuth } from '../contexts/AuthContext';  // Import the useAuth hook

import axios from 'axios';
import ForgotPassword from "src/pages/forgot-password";
import ResetPassword from "src/pages/reset-password";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response &&
      (error.response.status === 401 || error.response.status === 402 || error.response.status === 422 || error.response.data.message === 'Unauthenticated.')) {
        if (error.response.status === 402) {
          console.log("Stripe account required");
        }
        else {
          console.log('Unauthenticated error');
        }
        // Clear the auth token and redirect to the login page
        localStorage.removeItem('accessToken');
        localStorage.removeItem('authUser');
        
        if (!window.location.href.includes('/login')) {
          window.location.href = '/login';
        }
    }

    return Promise.reject(error);
  }
);

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: 'fixed'
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    // Not logged in path checks
    // Only allow access to the login and signup pages if the user is not logged in,
    // or publish shared agents.
    if (!isLoggedIn
      && location.pathname !== '/login'
      && location.pathname !== '/signup'
      && location.pathname !== '/signup-thank-you'
      && location.pathname !== '/forgot-password'
      && !location.pathname.startsWith('/reset-password')
      && !location.pathname.startsWith('/share/agent')
      && !location.pathname.startsWith('/share/workflow')) {
      navigate('/login');
    }

    // Logged in path checks
    // Automatically redirect to the chat page if the user is logged in and not on the chat page.
    // Exclude the agent and qasheet pages from this requirement.
    if (isLoggedIn
      && location.pathname !== '/chat'
      && !location.pathname.startsWith('/agent')
      && !location.pathname.startsWith('/qasheet')
      && location.pathname !== '/billing') {
      navigate('/chat');
    }
  }, [isLoggedIn, navigate, location]);


  return useRoutes([
    {
      path: '/',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'chat',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'signup',
      element: <Signup />
    },
    {
      path: 'signup-thank-you',
      element: <SignupThankYou />
    },
    {
      path: 'login',
      element: <Login />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/reset-password/:resetCode',
      element: <ResetPassword />
    },
    {
      path: 'share/agent/:agentId/revision/:agentRevisionId',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'share/agent/:agentId/:customAgentUrl/revision/:agentRevisionId',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'share/agent/:agentId/:customAgentUrl',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'agent/:agentId/qasheet',
      element: <QASheet />
    },
    {
      path: 'share/agent/:agentId',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'share/workflow/:workflowId',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'share/workflow/:workflowId/revision/:workflowRevisionId',
      element: <ChatLayout />,
      children: [
        {
          path: '',
          element: <Chat />
        }
      ]
    },
    {
      path: 'billing',
      element: <Billing />
    },
  ])
};

const Chat = Loadable(lazy(() => import('src/pages/chat')));
const QASheet = Loadable(lazy(() => import('src/pages/qasheet')));
const Login = Loadable(lazy(() => import('src/pages/login')));
const Signup = Loadable(lazy(() => import('src/pages/signup')));
const SignupThankYou = Loadable(lazy(() => import('src/pages/signup-thank-you')));
const Billing = Loadable(lazy(() => import('src/pages/billing')));
